'use client';

import { handleSentryMessage } from '@/utils/sentry';
import { Flex, Heading, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useEffect } from 'react';

export const NotFoundPage = () => {
   useEffect(() => {
      handleSentryMessage('Page not found');
   }, []);

   return (
      <Flex
         alignItems="center"
         justifyContent="center"
         minH="var(--app-height)"
         flexDir="column"
         gap={2}
      >
         <Heading>Page not found.</Heading>
         <Text color="background.primary" fontWeight={600}>
            <Link href="/">Return back to home</Link>
         </Text>
      </Flex>
   );
};
