import * as Sentry from '@sentry/nextjs';
import Axios from 'axios';

export const handleSentryException = (err: unknown) => {
   if (Axios.isAxiosError(err)) {
      Sentry.withScope((scope) => {
         scope.setExtra('Request url to the server', err.config?.url);
         scope.setExtra('Request Headers', err.config?.headers); // Authorization headers are filtered from Sentry side
         scope.setExtra('Response Headers', err.response?.headers);
         scope.setExtra('Params sent to the server', err.config?.params);
         scope.setExtra('Server response data', err.response?.data || 'None');

         Sentry.captureException(err);
      });
   } else {
      Sentry.captureException(err);
   }
};

type SentryExtra = {
   [x: string]: unknown;
};

export const handleSentryMessage = (
   message: string,
   level?: Sentry.SeverityLevel,
   extra?: SentryExtra,
) => {
   Sentry.captureMessage(message, {
      level: level || 'info',
      extra: extra || {},
   });
};
